<template>
  <div class="practice-box">
    <div class="container-header">
      <div class="header">
        <!-- <button :class="$style.textButton">
          <font-awesome-icon icon="fa-regular fa-circle-question" />
          <span :class="$style.help">Help</span>
        </button> -->
        <button class="textButton" @click="returnToHome">
          <span class="home">Return to Home</span>
          <i class="fa-solid fa-house-chimney" />
        </button>
      </div>
      <!-- <template v-if="practiceType === 'adaptivePractices'">
        <div class="content">
          <h1>{{ title }}</h1>
          <div class="directions">
            <el-form label-width="150px" label-position="top">
            <el-form-item label="Test Name">
              <b class="text-success">
                {{ title }}
              </b>
            </el-form-item>
            <el-form-item label="Questions">
              <b class="text-success">4</b>
              Sections
            </el-form-item>
            <el-form-item label="Time">
              <el-radio-group v-model="timeType">
                <el-radio-button :label="1">
                  Normal Time
                </el-radio-button>
                <el-radio-button :label="1.5">
                  Time and One-Half (+50%)
                </el-radio-button>
                <el-radio-button :label="2">
                  Double Time (+100%)
                </el-radio-button>
                <el-radio-button :label="0">
                  Not Timed (∞)
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Rating">
              <el-rate v-model="Rating" text-color="#ff9900" disabled></el-rate>
            </el-form-item>
          </el-form>
          </div>
        </div>
      </template> -->
      <template>
        <div class="content">
          <h1>{{ title }}</h1>
          <div class="directions">
            <el-form label-width="150px" label-position="top">
              <el-form-item label="Type" v-if="needTestClasses.length > 0">
                <el-radio-group v-model="forIvyway">
                  <el-radio-button :label="null">
                    不屬於班級
                  </el-radio-button>
                  <el-radio-button
                    v-for="(exam, index) in needTestClasses"
                    :key="index"
                    :label="exam"
                  >
                    {{ exam.title }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Class" v-if="forIvyway">
                <b class="text-success">
                  {{ forIvyway.title }}
                </b>
              </el-form-item>
              <el-form-item label="Test Name">
                <b class="text-success">
                  {{ title }}
                </b>
              </el-form-item>
              <el-form-item
                label="Questions"
                v-if="practiceType !== 'adaptivePractices'"
              >
                <span v-if="practices.length > 1">
                  <b class="text-success">{{ practices.length }}</b>
                  Modules,
                </span>
                <!-- <span v-else>
                  Module,
                </span> -->
                <b class="text-success">
                  {{ questionCount }}
                </b>
                Questions
              </el-form-item>
              <el-form-item label="Time" v-if="!isDrill">
                <el-radio-group v-model="timeType">
                  <el-radio-button :label="1">
                    Normal Time
                  </el-radio-button>
                  <el-radio-button :label="1.5">
                    Time and One-Half (+50%)
                  </el-radio-button>
                  <el-radio-button :label="2">
                    Double Time (+100%)
                  </el-radio-button>
                  <el-radio-button :label="0">
                    Not Timed (∞)
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Rating">
                <el-rate
                  v-model="Rating"
                  text-color="#ff9900"
                  disabled
                ></el-rate>
              </el-form-item>
              <el-form-item label="Coin(s) Required" v-if="!forIvyway">
                <template v-if="isPro || isAdmin">
                  <!-- <template v-if="false"> -->
                  <b class="originCoin mr-2">
                    {{ plan.coin }}
                    {{ plan.coin > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
                    <span class="removeLine"></span>
                  </b>
                  <span class="text-success" v-if="isAdmin">
                    <i class="fas fa-building"></i>
                    Free for Admin
                  </span>
                  <span
                    class="admin-Pro"
                    style="color: orange;"
                    v-else-if="isPro"
                  >
                    <i class="fas fa-crown"></i> Free for Pro
                  </span>
                </template>
                <div v-else>
                  <template v-if="freeForFirst">
                    <b class="originCoin mr-2">
                      {{ plan.coin }}
                      {{ plan.coin > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
                      <span class="removeLine"></span>
                    </b>
                    <span class="text-success">
                      Free
                    </span>
                  </template>
                  <b v-else>
                    {{ plan.coin }}
                    {{ plan.coin > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
                  </b>
                </div>
              </el-form-item>
            </el-form>
            <hr />
            <template v-if="forIvyway">
              <div class="text-right">
                <router-link
                  v-if="forIvyway.user_exam"
                  :to="{
                    name: isPhone ? 'SATMobileTest' : 'SATTest',
                    params: {
                      practiceType: practiceType,
                      id: $route.params.id
                    },
                    query: {
                      user_exam_id: forIvyway.user_exam.id
                    }
                  }"
                >
                  <el-button type="warning">
                    Resume
                  </el-button>
                </router-link>
                <el-button v-else type="primary" @click="startTestForIvyway">
                  Start
                </el-button>
              </div>
            </template>
            <template v-else>
              <template v-if="!(isPro || isAdmin || freeForFirst)">
                <el-form label-width="150px" label-position="top">
                  <el-form-item>
                    <span slot="label">
                      Your Balance
                    </span>
                    <b class="text-success">
                      {{ balance }}
                      {{ balance > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
                    </b>
                    <router-link
                      v-if="!isCompany"
                      :to="{
                        name: 'UserProfile',
                        query: { active: 'balance' }
                      }"
                      class="ml-2"
                    >
                      <el-button size="mini" type="primary">
                        Deposit
                      </el-button>
                    </router-link>
                  </el-form-item>
                </el-form>
                <h2 class="mb-3 text-right text-success">
                  {{ plan.coin }}
                  {{ plan.coin > 1 ? $t("sat.Coins") : $t("sat.Coin") }}
                </h2>
                <div class="text-right">
                  <el-button
                    type="primary"
                    :disabled="plan.coin > balance"
                    @click="unlockUserExam"
                  >
                    Pay and Start
                  </el-button>
                </div>
              </template>
              <template v-else>
                <div class="text-right">
                  <el-button type="primary" @click="startTest">
                    Start
                  </el-button>
                </div>
              </template>
            </template>
          </div>
        </div>
      </template>
      <!-- <el-dialog
        title="您有一份未完成的練習"
        :visible.sync="hasIvywayExam"
        width="60%"
        v-if="studentExam"
      >
        <h6>
          班級：<b class="text-success">{{ studentExam.title }}</b>
        </h6>
        <span slot="footer" class="dialog-footer">
          <el-button @click="hasIvywayExam = false">自己考試</el-button>
          <el-button type="primary" @click="setForIvyway"
            >完成班級練習</el-button
          >
        </span>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SAT from "@/apis/sat.js";
import balance from "@/apis/balance.js";
import role from "@/mixins/role.js";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {
    return {
      title: "Start - " + this.CompanyName
    };
  },

  components: {},

  mixins: [Common, role],

  props: [],
  data() {
    return {
      practices: [],
      practice: null,
      Rating: 5,
      timeType: 1,
      // isDrill: false,
      title: null,
      exam: null,
      needTestClasses: [],
      session_classes: [],
      forIvyway: null,
      hasIvywayExam: null,
      studentExam: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    freeForFirst() {
      return this.exam.is_trial > 0 && this.getProfile.trial_count > 0;
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    },
    balance() {
      return this.getProfile.coin;
    },
    questionCount() {
      let count = 0;
      if (this.practices && this.practices.length > 0) {
        this.practices.forEach(item => {
          count += item.exam.exam_questions.length;
        });
      }
      return count;
    },
    testId() {
      return this.$route.params.id;
    },
    practiceType() {
      return this.$route.params.practiceType;
    },
    isClass() {
      return true;
    },
    isDrill() {
      let isDrill = false;
      if (
        this.exam.category === 5 ||
        this.exam.category === 6 ||
        this.exam.category === 7 ||
        this.exam.category === 8 ||
        this.exam.category === 11 ||
        this.exam.category === 12
      ) {
        isDrill = true;
      }
      return isDrill;
    }
  },
  watch: {},

  async mounted() {
    let testId = this.$route.params.id;
    if (testId) {
      if (this.practiceType === "composePractices") {
        const res = await SAT.getComposePracticeTest(testId);
        this.practices = res.compose_practice.practices;
        this.practice = this.practices[0];
        this.exam = res.compose_practice.exam;
        this.title = this.getCompanyExamTitle(res.compose_practice.exam.title);
        this.plan = res.compose_practice.exam.coin_plans[0];
        // if ( res.compose_practice.marks.length > 0 && res.compose_practice.marks[0].name.en === "new") {
        //   this.isNew = true;
        // }
        this.isNew = true;
      } else if (this.practiceType === "adaptivePractices") {
        const res = await SAT.getAdaptive(testId);
        // this.practices = res.adaptive_practice.practices;
        // this.practice = this.practices[0];
        this.exam = res.adaptive_practice.exam;
        this.title = this.getCompanyExamTitle(res.adaptive_practice.exam.title);
        this.plan = res.adaptive_practice.exam.coin_plans[0];
        // if (res.adaptive_practice.marks.length > 0 && res.adaptive_practice.marks[0].name.en === "new") {
        //   this.isNew = true;
        // }
        this.isNew = true;
      } else if (this.practiceType === "singlePractice") {
        const res = await SAT.getPracticeTest(testId);
        this.practices = [res.practice];
        this.practice = res.practice;
        this.exam = res.practice.exam;
        this.title = this.getCompanyExamTitle(res.practice.exam.title);
        this.plan = res.practice.exam.coin_plans[0];
        this.isNew = true;
        // this.isDrill = true;
        // if (res.practice.marks.length > 0 && res.practice.marks[0].name.en === "new") {
        //   this.isNew = true;
        // }
        // if (res.practice.marks[1].name.en === "drill") {
        //   this.isDrill = true;
        // }
      }
      if (this.CompanyName === "Ivy-Way") {
        this.getIvywayClasses();
        this.ivywayClassesId = this.$router.query.session_classes_id;
      }
    }
  },

  methods: {
    setForIvyway() {
      this.forIvyway = true;
      this.hasIvywayExam = false;
    },
    async startTest() {
      let res = null;
      if (this.isAdmin) {
        res = await balance.unLockAdminExam(this.exam.id, {
          user_exam: {
            other_status: {
              timeType: this.timeType
            }
          }
        });
      } else if (this.isPro) {
        res = await balance.unlockProUserExam(this.exam.id, {
          user_exam: {
            other_status: {
              timeType: this.timeType
            }
          }
        });
      } else if (this.freeForFirst) {
        res = await balance.unlockFreeForFirst(this.exam.id, {
          user_exam: {
            other_status: {
              timeType: this.timeType
            }
          }
        });
      }
      if (res && res.user_exam && res.user_exam.id && res.user_exam.id > 0) {
        this.$router.replace({
          name: this.isPhone ? "SATMobileTest" : "SATTest",
          params: {
            practiceType: this.practiceType,
            id: this.$route.params.id
          },
          query: {
            user_exam_id: res.user_exam.id
          }
        });
      }
    },
    async startTestForIvyway() {
      let res = null;
      res = await SAT.startTestForIvyway(this.exam.id, {
        ivy_way_session_class_assign_test_id: this.forIvyway
          .session_class_assign_test_id,
        user_exam: {
          other_status: {
            timeType: this.timeType
          }
        }
      });
      if (res && res.user_exam && res.user_exam.id && res.user_exam.id > 0) {
        this.$router.replace({
          name: this.isPhone ? "SATMobileTest" : "SATTest",
          params: {
            practiceType: this.practiceType,
            id: this.$route.params.id
          },
          query: {
            user_exam_id: res.user_exam.id
          }
        });
      }
    },
    async unlockUserExam() {
      const res = await balance.unlockUserExam(this.exam.id, {
        user_exam: {
          other_status: {
            timeType: this.timeType
          }
        }
      });
      if (res && res.user_exam && res.user_exam.id && res.user_exam.id > 0) {
        this.$router.replace({
          name: this.isPhone ? "SATMobileTest" : "SATTest",
          params: {
            practiceType: this.practiceType,
            id: this.$route.params.id
          },
          query: {
            user_exam_id: res.user_exam.id
          }
        });
      }
    },
    async getIvywayClasses() {
      const res = await SAT.getIvywayClasses(this.exam.id);
      const session_classes = res.session_classes;
      if (session_classes && session_classes.length > 0) {
        let needTestClasses = [];
        this.session_classes = session_classes;

        session_classes.forEach((studentExam, index) => {
          if (
            !studentExam.user_exam ||
            (studentExam.user_exam && studentExam.user_exam.is_finished == 0)
          ) {
            needTestClasses.push(studentExam);
          }
        });
        this.needTestClasses = needTestClasses;
        // this.studentExam = session_classes[0];
        // if (
        //   !this.studentExam.user_exam ||
        //   (this.studentExam.user_exam &&
        //     this.studentExam.user_exam.is_finished == 0)
        // ) {
        //   this.hasIvywayExam = true;
        // }
      }
    },
    returnToHome() {
      this.$router.replace({
        name: "SATList"
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
}
::v-deep .el-form-item__content {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.5rem;
}
.originCoin {
  font-size: 16px;
  color: #aaa;
  position: relative;
  line-height: 0.8;
  font-weight: 700;
}
.removeLine {
  border-top: 2px solid #aaa;
  position: absolute;
  left: 0px;
  top: 12px;
  transform: rotate(-6deg);
  width: 50px;
}
.practice-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  font-weight: 500;
  /* user-select: none; */
}
</style>
<style lang="scss" scope>
.textButton {
  background: transparent;
  border: 0rem;
  padding: 0rem;
  font-size: 1rem;
  cursor: pointer;
}
.container-header {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: max-content 1fr;

  .header {
    padding: 1rem 2.5rem 1.2rem 2.5rem;
    display: flex;
    justify-content: space-between;

    .help {
      margin-left: 0.25rem;
    }

    .home {
      margin-right: 0.25rem;
    }
  }

  .content {
    background: #faf9fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    h1 {
      font-weight: 400;
      margin-bottom: 2rem;
    }

    .directions {
      background: #ffffff;
      padding: 2rem 3rem;
      box-shadow: 0 0.5rem 1rem #dcdcdc;
      min-width: 40rem;
      // h2 {
      //   font-size: 1.2rem;
      //   margin-bottom: 2rem;
      //   font-weight: 400;
      // }

      .tools {
        // display: grid;
        // grid-gap: 1rem;
        // grid-template-columns: repeat(2, 1fr);
        // grid-template-rows: repeat(3, 1fr);

        .tool {
          display: grid;
          grid-template-columns: 3rem 1fr;
          grid-gap: 1rem;
          width: 40rem;
          min-height: 5.5rem;
          margin-bottom: 2rem;

          .toolIcon {
            border-radius: 2rem;
            width: 2.5rem;
            height: 2.5rem;
            font-size: 1.2rem;
            background: #dcdcdc;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          h3 {
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 1rem;
          }

          p {
            margin-top: -0.2rem;
            line-height: 1.6rem;
          }
        }
      }
    }
  }

  .footer {
    border-top: 0.25rem solid #e9e9e9;
    padding: 0 2.5rem;
    display: grid;
    grid-template-columns: 3fr 8fr 3fr;
    grid-gap: 2rem;
    justify-content: space-between;
    align-items: center;

    .stepButton {
      font-weight: bold;
      padding: 0.5rem 3rem;
      border-radius: 1rem;
      border: 0.1rem var(--themeColor) solid;
      cursor: pointer;
    }

    .back {
      background: #ffffff;
    }

    .right {
      text-align: right;
      .next {
        background: var(--themeColor);
        color: white;
      }
    }

    .step {
      text-align: center;
      justify-self: center;
      width: 100%;

      .stepBottomLine {
        margin-top: 0.8rem;
        width: 100%;
        border-top: 0.4rem solid #e7f1f9;
        border-radius: 0.2rem;
        position: relative;

        .stepLine {
          position: absolute;
          border-top: 0.4rem solid var(--themeColor);
          border-radius: 0.2rem;
          top: -0.4rem;
          left: 0rem;
          transition: width 0.5s;
        }
      }
    }
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  .container-header {
    .header {
      padding: 1rem;
    }
    .content {
      h1 {
        margin-bottom: 1rem;
        font-size: 1.8rem;
      }
      width: 100%;
      padding: 1rem;
      .directions {
        background: #ffffff;
        padding: 1rem;
        box-shadow: 0 0.5rem 1rem #dcdcdc;
        width: 100%;
        min-width: 10rem;
        .tools {
          .tool {
            display: grid;
            grid-template-columns: 3rem 1fr;
            grid-gap: 1rem;
            width: 40rem;
            min-height: 5.5rem;
            margin-bottom: 2rem;
            .toolIcon {
              border-radius: 2rem;
              width: 2.5rem;
              height: 2.5rem;
              font-size: 1.2rem;
              background: #dcdcdc;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            h3 {
              font-weight: 700;
              margin-top: 0;
              margin-bottom: 1rem;
            }
            p {
              margin-top: -0.2rem;
              line-height: 1.6rem;
            }
          }
        }
      }
    }
  }
}
</style>
